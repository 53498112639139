import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import Footer from "../../../../common/Footer/Footer";
import axios from "axios";
import Menu1 from "../../../../common/Menu/Menu1";
import SidebarPage from "../../../../common/Menu/SidebarPage";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import "./ElectroMechanical.css";
import ElectroMechanicalBanner from "./ElectroMechanicalBanner";

function ElectroMechanical() {
  const [addprd, setAddprd] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const allData = () => {
    const URL = "http://localhost:9000/getaddElectroMechanical/";
    axios.get(URL)
      .then((response) => setAddprd(response.data))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    allData();
  }, []);

  const handleFavoriteClick = async (productId, productname, description, image) => {
    try {
      await axios.post("http://localhost:9000/registerFavoriteProduct", {
        productname,
        description,
        image,
      });
      console.log("Product registered successfully");
      // Optionally, provide feedback to the user upon successful registration
    } catch (error) {
      console.error("Error registering product:", error);
    }
  };

  return (
    <>
      <div className="content-container">
        <Menu1 header_style_2={true} />
        <ElectroMechanicalBanner/>
        <div className="container-wrapper">
          <div className="sidebar-container">
            <SidebarPage />
          </div>

          <Container className="my-5">
            <div className="row justify-content-center">
              <h2 className="fitting-header">ElectroMechanical</h2>
              <p className="fitting-description">
                Our comprehensive range of ElectroMechanical includes essential
                components such as elbows, couplings, unions, reducers, olets,
                tees, crosses, and plug nipples. These precision-engineered
                ElectroMechanical play a vital role in ensuring seamless connections,
                efficient fluid flow, and reliable performance across various
                industrial applications. Designed for durability and
                versatility, our ElectroMechanical are crafted to meet the highest
                standards, providing the essential building blocks for robust
                piping systems.
              </p>

              {addprd.map((product) => (
                <Card className="card-body1"
                 
                  onMouseEnter={() => setHoveredItem(product._id)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <img
                    src={`http://localhost:9000/${product.image}`}
                    alt="Product Image"
                    style={{
                      maxWidth: "100%",
                      height: "150px",
                    }}
                  />
                  {hoveredItem === product._id && (
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "#fa7d85", border: "none" }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event propagation to the card
                          handleFavoriteClick(product._id, product.productname, product.description, product.image);
                        }}
                      >
                        <FavoriteBorderIcon />
                      </Button>
                    </div>
                  )}

                  <Card.Body>
                    <Card.Title>{product.productname}</Card.Title>
                    <Card.Text>{product.description}</Card.Text>
                    <Link to={`/ElectroMechanicalProduct/${product._id}`}>
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#0398fc", border: "none" }}
                      >
                        Select
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ElectroMechanical;
