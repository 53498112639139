import React, { useEffect, useRef } from 'react';
import './Preloader.css'; // Import CSS for styling

const Preloader = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particles = [];

    // Function to generate random color
    const randomColor = () => {
      return `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`;
    };

    // Function to create a particle
    const createParticle = () => {
      const radius = Math.random() * 3 + 1; // Random radius between 1 and 4
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const color = randomColor();
      const velocity = {
        x: (Math.random() - 0.5) * 2, // Random velocity between -1 and 1
        y: (Math.random() - 0.5) * 2,
      };
      particles.push({ x, y, radius, color, velocity });
    };

    // Function to animate particles
    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle, index) => {
        particle.x += particle.velocity.x;
        particle.y += particle.velocity.y;

        if (particle.x + particle.radius < 0 || particle.x - particle.radius > canvas.width ||
          particle.y + particle.radius < 0 || particle.y - particle.radius > canvas.height) {
          particles.splice(index, 1); // Remove particles that move out of the canvas
        } else {
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
          ctx.fillStyle = particle.color;
          ctx.fill();
        }
      });

      while (particles.length < 100) {
        createParticle(); // Generate new particles to maintain a constant number of particles
      }
    };

    // Set canvas size to fill the screen
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Start animation
    animate();

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(animate);
    };
  }, []);

  return <canvas ref={canvasRef} className="mind-blowing-animation"></canvas>;
};

export default Preloader;
