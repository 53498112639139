import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card } from "react-bootstrap";
import Menu1 from '../../../common/Menu/Menu1';
import Footer from '../../../common/Footer/Footer';
import './FavoritePage.css';

function FavoritePage() {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleDelete = (id) => {
    const url = `http://localhost:9000/deleteFavoriteProduct/${id}`;
    axios.delete(url)
      .then((res) => {
        alert(res.data);
        // After deletion, fetch the updated list of favorites
        fetchData();
      })
      .catch((err) => {
        console.error('Error deleting item:', err);
      });
  };

  const fetchData = () => {
    // Fetch data from the 'favoriteProducts' endpoint
    axios.get('http://localhost:9000/favoriteProducts')
      .then((res) => {
        // Update the state with the fetched data
        setFavoriteProducts(res.data);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  };

  useEffect(() => {
    // Fetch data on component mount
    fetchData();
  }, []); // Empty dependency array to run the effect only once

  return (
    <>
      <Menu1 header_style_2={true} />
      <div className='centered-container'>
        <h2>Your favorite list</h2>
      </div>
      <div className="favorite-container">
        {favoriteProducts.map((product) => (
          <Card
            key={product._id}
            style={{
              width: "16rem",
              border: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease-in-out",
              position: "relative",
              margin: "0 10px 20px",
            }}
            onMouseEnter={() => setHoveredItem(product._id)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Card.Img variant="top" src={`http://localhost:9000/${product.image}`} />
            <Card.Body>
              <Card.Title>{product.productname}</Card.Title>
              <Card.Text>{product.description}</Card.Text>
              <Button
                className="btn btn-danger"
                onClick={() => handleDelete(product._id)}
              >
                Delete
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default FavoritePage;
