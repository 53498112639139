import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          About Us
                        </h3>
                        <ul>
                        <li>
                          <a href="#s">Proficiency</a>
                        </li>
                        <li>
                          <a href="#s">Adaptability</a>
                        </li>
                        <li>
                          <a href="#s">Constability</a>
                        </li>
                        <li>
                          <a href="#s">Ingenuity</a>
                        </li>
                        
                      </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Our Service</h3>
                        <ul>
                          <li>
                            <a href="#s">Versatility</a>
                          </li>
                          <li>
                            <a href="#s">Affordability </a>
                          </li>
                          <li> 
                            <a href="#s">Expertise  </a>
                          </li>
                          <li>
                            <a href="#s"> Reliability</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <a href="#s">Our Team</a>
                          </li>
                          <li>
                            <a href="#s">Faq</a>
                          </li>
                          <li>
                            <a href="#s">Portfolio</a>
                          </li>
                          <li>
                            <a href="#s">Service</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Contact Us</h3>
                        <ul>
                          <li>
                            <a href="#s">
                            Dubai Office Address:<br/>
                            PO Box: 380575 
                            Address: IFZA Dubai - Building A2 - 103
                            DSO, Dubai, UAE 
                            </a>
                          </li>
                          
                          <li>
                            <a href="tel:00971527201651">Mob: 00971527201651</a>
                          </li>
                          <li>
                            <a href="mailto:sales@orionworld.co">
                            sales@orionworld.co
                            </a>
                          </li>
                          <li className="top-gap">
                            <a href="s">
                              Abu Dhabi Address:<br/>
                              Hamed Bin Butti Al Qubaisi ,116 St,<br/>
                               Al Zahiyah, Abu Dhabi 22223<br/>
                              Noura Majed Mohammed Al Futtaim Bldg,<br/> 
                              Fifth floor, office F9-07
                            </a>
                          </li>
                          <li>
                            <a href="tel:0097126751657">
                              TEL: 00971 2 675 1657
                            </a>
                          </li>
                          <li>
                            <a href="tel:00971527201651">
                              MOB: 00971527201651
                            </a>
                          </li>
                          <li>
                            <a href="mailto:sales@orionworld.co">
                              sales@orionworld.co
                            </a>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                     Orion General Trading LLC © {new Date().getFullYear()}. All rights reserved.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  <ul>
                    <li>
                      <a href="#s">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#s">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#s">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#s">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#s">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
