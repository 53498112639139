import React, { useState, useEffect } from "react";
import Navbr from "../navbr";
import AXIOS from "axios";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";

const EditelectroMechanical = () => {
  const [addelectroMechanical, setAddelectroMechanical] = useState([]);

  const deleteItem = (idno) => {
    const url = `http://localhost:9000/deleteelectroMechanical/${idno}`; // Corrected typo in URL
    AXIOS.delete(url)
      .then((res) => {
        alert(res.data);
        // After successful deletion, fetch the updated data
        allData();
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };

  const allData = () => {
    const URL = "http://localhost:9000/getaddelectroMechanical/"; // Corrected typo in URL
    AXIOS.get(URL)
      .then((response) => setAddelectroMechanical(response.data))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    allData();
  }, []);

  return (
    <>
      <Navbr />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="w-100 bg-white rounded p-3" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <Link to="/addelectroMechanical" className="btn btn-success">
          Add+
        </Link>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Image</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {addelectroMechanical.map((electroMechanical) => (
              <tr key={electroMechanical._id}>
                <td>
                  <img
                    src={`http://localhost:9000/${electroMechanical.image}`} // Corrected typo in URL
                    alt="Product Image"
                    style={{ maxWidth: "70px", height: "auto" }}
                  />
                </td>
                <td>{electroMechanical.productname}</td>
                <td>{electroMechanical.description}</td>
                <td>
                  <Link
                    to={`/updateelectroMechanical/${electroMechanical._id}`}
                    className="btn btn-success"
                  >
                    Update
                  </Link>
                  <Button
                    onClick={() => {
                      deleteItem(electroMechanical._id);
                    }}
                    className="btn btn-danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EditelectroMechanical;
