import React from 'react'
import FooterThree from '../../common/Footer/FooterThree';
import Menu from '../../common/Menu/Menu';
import Contact from '../../common/Contact/Contact';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ServiceDetailsBanner from '../ServiceDetails/ServiceDetailsBanner';

const Products = () => {
  return (
    <>
    <Menu/>
    <ServiceDetailsBanner/>
    <div class="container">
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="assets/img/cards/card1.jpg" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <Button variant="primary">Select</Button>
      </Card.Body>
    </Card>
    </div>
    <Contact/>
    <FooterThree/>
    </>
  )
}

export default Products;