import React from "react";

const SidebarPage = () => {
  return (
    <div className="sidebar">
        
      <h2 className="sidebar-title">Category</h2>
      {/* Add more navigation links as needed */}
      <a className="sidebar-element" href="/fittings">Fittings</a>
      <br />
      <a className="sidebar-element" href="/electro-mechanical">ElectroMechanical</a>
      <br />
      <a className="sidebar-element" href="/safety">Safety</a>
      <br />
      <a className="sidebar-element" href="/welding">Welding</a>
      <br />
      <a className="sidebar-element" href="/flanges">Flanges</a>
      <br />
      <a className="sidebar-element" href="/valves">Valves</a>
      <br />
      <a className="sidebar-element" href="/fasteners">Fasteners</a>
      <br />
      <a className="sidebar-element" href="/instrumentation">Instrumentation</a>
      <br />
      <a className="sidebar-element" href="/lifting-tackles">LiftingTackles</a>
      <br />
      <a className="sidebar-element" href="/fender">Fender</a>
      <br />
      <a className="sidebar-element" href="/electrical">Electrical</a>
      <br />
      <a className="sidebar-element" href="/other-general-items">OtherGeneralItems</a>
      <br />
    </div>
  );
};

export default SidebarPage;
