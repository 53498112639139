import React from 'react';
import Navbr from './navbr';

const Adminhome = () => {
  return (
    <>
      <div style={{ backgroundColor: '#f0faff', minHeight: '100vh' }}>
        <Navbr />
        <br /><br /><br /><br />
        <h1 >Welcome</h1>
      </div>
    </>
  );
};

export default Adminhome;
