import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import Footer from "../../../../common/Footer/Footer";
import AXIOS from "axios";
import Menu1 from "../../../../common/Menu/Menu1";
import SidebarPage from "../../../../common/Menu/SidebarPage";
import { Link, useNavigate } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import "./Safety.css";

function Safety() {
  const [addprd, setAddprd] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();

  const allData = () => {
    const URL = "http://localhost:9000/getaddSafety/"; // Corrected typo in URL
    AXIOS.get(URL)
      .then((response) => setAddprd(response.data))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    allData();
  }, []);

  const handleFavoriteClick = (productId) => {
    const selectedProduct = addprd.find((product) => product._id === productId);
    // Send only necessary data to the backend for registering as favorite
    const productData = {
      _id: selectedProduct._id,
      productname: selectedProduct.productname,
      description: selectedProduct.description,
      image: selectedProduct.image,
    };

    AXIOS.post("http://localhost:9000/registerFavoriteProduct", productData)
      .then((response) => {
        console.log("Product registered successfully:", response.data);
        // Optionally, provide feedback to the user upon successful registration
      })
      .catch((error) => {
        console.error("Error registering product:", error);
      });
  };

  return (
    <>
      <div className="content-container">
        <Menu1 header_style_2={true} />
        <div className="container-wrapper">
          <div className="sidebar-container">
            <SidebarPage />
          </div>

          <Container className="my-5">
            <div className="row justify-content-center">
              <h2 className="fitting-header">Safety</h2>
              <p className="fitting-description">
                Our comprehensive range of Safety includes essential
                components such as elbows, couplings, unions, reducers, olets,
                tees, crosses, and plug nipples. These precision-engineered
                Safety play a vital role in ensuring seamless connections,
                efficient fluid flow, and reliable performance across various
                industrial applications. Designed for durability and
                versatility, our Safety are crafted to meet the highest
                standards, providing the essential building blocks for robust
                piping systems.
              </p>

              {addprd.map((product) => (
                <Card
                  key={product._id}
                  style={{
                    width: "16rem",
                    border: "none",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease-in-out",
                    transform:
                      hoveredItem === product._id ? "scale(1.05)" : "scale(1)",
                    position: "relative",
                    margin: "0 10px 20px",
                  }}
                  onMouseEnter={() => setHoveredItem(product._id)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <img
                    src={`http://localhost:9000/${product.image}`} // Corrected typo in URL
                    alt="Product Image"
                    style={{
                      maxWidth: "100%",
                      height: "150px",
                    }}
                  />
                  {hoveredItem === product._id && (
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "#fa7d85", border: "none" }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event propagation to the card
                          handleFavoriteClick(product._id);
                        }}
                      >
                        <FavoriteBorderIcon />
                      </Button>
                    </div>
                  )}

                  <Card.Body>
                    <Card.Title>{product.productname}</Card.Title>
                    <Card.Text>{product.description}</Card.Text>
                    <Link to={`/SafetyProduct/${product._id}`}>
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#0398fc", border: "none" }}
                      >
                        Select
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Safety;
