import { Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Row , Button} from 'react-bootstrap';
import { useState } from 'react';
import AXIOS from 'axios'

function NewProducts() {
    const [pname,setPname]=useState("")
    const [pdesc,setPdesc]=useState("")
    const addData=()=>{
        const url="http://localhost:9000/NewProducts";
        AXIOS.post(url,{pname},{pdesc}).then((res)=>{
            alert(res.data)
        })
    }
  return (
    <>
    <Container>
       <Row className="justify-content-center mt-3">
          <Col lg={4} className="border p-3">
            <Form onSubmit={addData}>
              <Form.Group>
                <Form.Label htmlFor="productname">Product Name</Form.Label>
                <Form.Control
                  id="productname"
                  type="text"
                  onChange={(e)=>setPname(e.target.value)}
                  placeholder="Enter your Product name"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="productdesc">Description</Form.Label>
                <Form.Control
                  id="productdesc"
                  type="texy"
                  onChange={(e)=>setPdesc(e.target.value)}
                  placeholder="Enter your Description"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Button type="submit" >
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        </Container>
    </>
  );
}

export default NewProducts;