import React, { useState } from "react";
import axios from 'axios';

const ContactInfo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axios.post("http://localhost:9000/contactinfo", {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      });
      setResponseMessage(response.data.msg15);
      // Clear the form after successful submission
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
      // Handle error here, display an error message or perform other actions
      setResponseMessage("Error occurred while submitting the form.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="contact-from-section pt-140">
        <div className="container">
          {/* Contact information boxes */}
          {/* Your existing contact information boxes */}
          {/* Contact form */}
          <div className="row">
            <div className="col-12">
              <div className="tp-ct-form white-bg pl-110 pr-110 pt-80 pb-130">
                <form onSubmit={handleFormSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Enter your number*"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject*"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Enter your Massage*"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                  <div className="text-center">
                    <button type="submit" className="tp-btn-border" disabled={submitting}>
                      {submitting ? "Sending..." : "Send Massage"}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
                <p className="ajax-response mt-20 text-center">{responseMessage}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-ct-map">
          {/* Your existing map component */}
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
