import React, { useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import Footer from "../../../../common/Footer/Footer";
import AXIOS from "axios";
import Menu1 from "../../../../common/Menu/Menu1";
import SidebarPage from "../../../../common/Menu/SidebarPage";
import { Link } from "react-router-dom";

import "./Electrical.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function Electrical() {
  const [addprd, setAddprd] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS.get("http://localhost:9000/getaddElectrical/");
        setAddprd(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleFavoriteClick = async (productId) => {
    try {
      const selectedProduct = addprd.find((product) => product._id === productId);
      const response = await AXIOS.post("http://localhost:9000/registerFavoriteProduct", selectedProduct);
      console.log("Product registered successfully:", response.data);
    } catch (error) {
      console.error("Error registering product:", error);
    }
  };

  return (
    <>
      <div className="content-container">
        <Menu1 header_style_2={true} />
        <div className="container-wrapper">
          <div className="sidebar-container">
            <SidebarPage />
          </div>
          <Container className="my-5">
            <div className="row justify-content-center">
              <h2 className="fitting-header">Electrical</h2>
              <p className="fitting-description">
                Our comprehensive range of Electrical includes essential components such as elbows, couplings, unions, reducers, olets, tees, crosses, and plug nipples. These precision-engineered Electrical play a vital role in ensuring seamless connections, efficient fluid flow, and reliable performance across various industrial applications. Designed for durability and versatility, our Electrical are crafted to meet the highest standards, providing the essential building blocks for robust piping systems.
              </p>
              {addprd.length > 0 ? (
                addprd.map((product) => (
                  <Card
                    key={product._id}
                    style={{
                      width: "16rem",
                      border: "none",
                      boxShadow: `0 4px 8px rgba(0, 0, 0, ${hoveredItem === product._id ? "0.2" : "0.1"})`,
                      transition: "transform 0.3s ease-in-out",
                      transform: hoveredItem === product._id ? "scale(1.05)" : "scale(1)",
                      position: "relative",
                      margin: "0 10px 20px",
                    }}
                    onMouseEnter={() => setHoveredItem(product._id)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <img
                      src={`http://localhost:9000/${product.image}`}
                      alt={product.productname}
                      style={{
                        maxWidth: "100%",
                        height: "150px",
                      }}
                    />
                    {hoveredItem === product._id && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {/* Add favorite button/icon here if needed */}
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title>{product.productname}</Card.Title>
                      <Card.Text>{product.description}</Card.Text>
                      <Link to={`/ElectricalProduct/${product._id}`}>
                        <Button variant="primary" style={{ backgroundColor: "#0398fc", border: "none" }}>
                          Select
                        </Button>
                      </Link>
                      <Button style={{ backgroundColor: "#fa7d85", border: "none" }} onClick={() => handleFavoriteClick(product._id)}>
                        <FavoriteBorderIcon />
                      </Button>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <h2>No Products</h2>
              )}
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Electrical;
